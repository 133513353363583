import { gql } from "@apollo/client"

export const ESTATE_DETAIL = gql`
  query Estate($alias: String!) {
    estates(alias: $alias) {
      id
      advertPrice
      advertPriceLegalServices
      advertPriceTextNote
      advertPriceVat
      advertSubtype
      alias
      balcony
      basin
      buildingArea
      buildingCondition
      buildingType
      cellar
      description
      energyEfficiencyRating
      estateArea
      floorArea
      floors
      garage
      gardenArea
      objectKind
      objectType
      ownership
      usableArea
      name
      sellerRkid
      files(order: { order: "ASC" }) {
        filename
      }
    }
  }
`

export const ESTATE_LIST = gql`
  query Estates {
    estates {
      id
      name
      advertPrice
      alias
      files(order: { order: "ASC" }) {
        filename
      }
    }
  }
`
