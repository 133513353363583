import React from "react"
import { ESTATE_DETAIL } from "../../query/estate"
import { useQuery } from "@apollo/client"
import { SRLWrapper } from "simple-react-lightbox"
import { MailIcon, PhoneIcon } from "@heroicons/react/solid"
import { StaticImage } from "gatsby-plugin-image"
import Layout from "../layout"
import { useParams } from "@reach/router"
import { Loading } from "../layout/Loading"

export const EstateDetail = () => {
  const { alias } = useParams()
  const { loading, error, data } = useQuery(ESTATE_DETAIL, {
    variables: {
      alias: alias,
    },
    fetchPolicy: "cache-and-network",
  })

  const estate = data ? data.estates[0] : null

  const estateData = estate
    ? [
        {
          name: "Cena",
          value: estate.advertPrice + " " + estate.advertPriceVat,
        },
        {
          name: "Poznámka k ceně",
          value:
            estate.advertPriceTextNote + ", " + estate.advertPriceLegalServices,
        },
        {
          name: "Druh objektu",
          value: estate.advertSubtype + ", " + estate.objectKind,
        },
        { name: "Stavba", value: estate.buildingType },
        {
          name: "Energetická náročnost budovy",
          value: estate.energyEfficiencyRating,
        },
        { name: "Balkón", value: estate.balcony },
        { name: "Sklep", value: estate.cellar },
        { name: "Garáž", value: estate.garage },
        { name: "Bazén", value: estate.basin },
        { name: "Objekt", value: estate.advertType },
        { name: "Typ domu", value: estate.objectType },
        { name: "Užitná plocha", value: estate.usableArea },
        { name: "Počet podlaží", value: estate.floors },
        { name: "Plocha pozemku", value: estate.estateArea },
        { name: "Plocha zahrady", value: estate.gardenArea },
        { name: "Stav objektu", value: estate.buildingCondition },
        { name: "Plocha zastavěná", value: estate.buildingArea },
        { name: "Podlahová plocha", value: estate.floorArea },
        { name: "Vlastnictví", value: estate.ownership },
        { name: "Zakázka", value: estate.name },
        { name: "Kód zakázky", value: estate.advertCode },
      ]
    : []

  const Gallery = () => {
    const files = estate.files

    return (
      <SRLWrapper>
        <div className="grid grid-cols-2 lg:grid-cols-3 gap-3">
          {files.map((e, index) => {
            return (
              <div
                key={index}
                className={
                  "overflow-hidden " +
                  (index === 0 ? "col-span-2 lg:col-span-3" : "h-32 ")
                }
              >
                <img
                  className="cursor-pointer object-cover min-h-full"
                  src={e.filename}
                />
              </div>
            )
          })}
        </div>
      </SRLWrapper>
    )
  }
  return (
    <>
      {loading && <Loading loading={true} />}

      {estate && (
        <div className=" max-w-7xl mx-auto pt-12 sm:pt-16">
          <div className=" px-4 sm:px-6 lg:px-8">
            <div className="max-w-7xl mx-auto ">
              <h2 className="text-2xl inset-0 sm:text-4xl">{estate.name}</h2>
            </div>

            <div className="mt-10 pb-12 bg-white sm:pb-16 grid lg:grid-cols-2 gap-3">
              <Gallery />
              <div className="relative lg:px-5">
                <div className="text-2xl">
                  Cena: <span className=" font-bold">{estate.advertPrice}</span>
                </div>
                <div className="  p-3 mb-3">{estate.description}</div>

                <div className="bg-white shadow overflow-hidden sm:rounded-lg">
                  <div className="border-t border-gray-200">
                    <dl>
                      {estateData.map((e, i) => {
                        return (
                          <div
                            key={i}
                            className={
                              " px-4 py-3 grid grid-cols-3 sm:grid-cols-5 sm:gap-4 sm:px-6 " +
                              (i % 2 === 0 ? "bg-gray-50" : "bg-white")
                            }
                          >
                            <dt className="text-sm font-medium text-gray-500 sm:col-span-2">
                              {e.name}
                            </dt>
                            <dd className="mt-1 text-sm text-gray-900 sm:mt-0 col-span-2 sm:col-span-3">
                              {e.value}
                            </dd>
                          </div>
                        )
                      })}
                    </dl>
                  </div>
                </div>
                {estate.sellerRkid === "Mgr. Radek Vach, MBA" ? (
                  <div className="space-y-12 my-10 space-x-2  lg:mx-auto overflow-hidden">
                    <div>
                      <div className="flex space-x-4 ">
                        <div className=" overflow-hidden  w-24 h-42 flex rounded-lg">
                          <StaticImage
                            src="../../images/vach.png"
                            layout="fixed"
                            height={150}
                            className="  object-cover "
                            alt="Mgr. Radek Vach, MBA"
                          />
                        </div>

                        <div className="space-y-2">
                          <div className="text-lg leading-6 p-6 md:px-0 lg:p-0 font-medium space-y-2">
                            <div className=" items-center text-base ">
                              <h3 className="text-xl text-blue-600 font-semibold">
                                Mgr. Radek Vach, MBA
                              </h3>
                              <h2 className="text-xs text-gray-400 font-semibold tracking-wide uppercase">
                                realitní makléř
                              </h2>
                            </div>
                            <div className=" flex items-center text-base ">
                              <PhoneIcon
                                className="h-4 w-4 text-gray-400 mr-3"
                                aria-hidden="true"
                              />
                              <span>774 785 167</span>
                            </div>
                            <div className=" flex items-center text-base ">
                              <MailIcon
                                className="h-4 w-4 text-gray-400 mr-3"
                                aria-hidden="true"
                              />
                              <a
                                href="mailto:reality@malgen.cz"
                                rel="noreferrer"
                              >
                                reality@malgen.cz
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="space-y-12 my-10 space-x-2  lg:mx-auto overflow-hidden">
                    <div>
                      <div className="flex space-x-4 ">
                        <div className=" overflow-hidden  w-24 h-42 flex rounded-lg">
                          <StaticImage
                            src="../../images/kouril.jpg"
                            layout="fixed"
                            height={150}
                            className="  object-cover "
                            alt="Vladimír Kouřil"
                          />
                        </div>

                        <div className="space-y-2">
                          <div className="text-lg leading-6 p-6 md:px-0 lg:p-0 font-medium space-y-2">
                            <div className=" items-center text-base ">
                              <h3 className="text-xl text-blue-600 font-semibold">
                                Vladimír Vach
                              </h3>
                              <h2 className="text-xs text-gray-400 font-semibold tracking-wide uppercase">
                                realitní makléř
                              </h2>
                            </div>
                            <div className=" flex items-center text-base ">
                              <PhoneIcon
                                className="h-4 w-4 text-gray-400 mr-3"
                                aria-hidden="true"
                              />
                              <span>774 729 538</span>
                            </div>
                            <div className=" flex items-center text-base ">
                              <MailIcon
                                className="h-4 w-4 text-gray-400 mr-3"
                                aria-hidden="true"
                              />
                              <a
                                href="mailto:reality@malgen.cz"
                                rel="noreferrer"
                              >
                                reality@malgen.cz
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  )
}
