import React from "react"
import { Helmet } from "react-helmet"
import { Router } from "@reach/router"
import { EstateDetail } from "../components/estate/EstateDetail"

const NemovitostPage = () => (
  <>
    <Helmet>
      <meta charSet="utf-8" />
      <title>Nemovitost</title>
    </Helmet>

    <Router>
      <EstateDetail path="/nemovitost/:alias" />
    </Router>
  </>
)

export default NemovitostPage
